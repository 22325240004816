<template>
    <div class="forgot-password-route">
        <language-select-unauthorized
            class="language-selector"
        />
        <clouds v-if="welcomeTheme === 'CLOUDS'" />
        <astypalaia v-else-if="welcomeTheme === 'ASTYPALAIA'" />
        <clouds-with-gifts v-else />
        <div class="forgot-password-container">
            <div class="forgot-password-title">
                <b-img
                    :src="branding.primaryLogoUrl"
                    class="mx-2 pt-2"
                    alt=" "
                />
            </div>
            <div v-if="isPasswordResetRoute" class="forgot-password-text">
                {{ $t('forgotPasswordRoute.guideline') }}
            </div>
            <div v-else class="forgot-password-text">
                {{ $t('forgotPasswordRoute.newPasswordForm.guideline') }}
            </div>
            <reset-password-form v-if="isPasswordResetRoute" />
            <new-password-form v-else />
        </div>
    </div>
</template>

<script>
    import ResetPasswordForm from '@/components/ResetPasswordForm'
    import NewPasswordForm from '@/components/NewPasswordForm'
    import LanguageSelectUnauthorized from '@/components/LanguageSelectUnauthorized'
    import CloudsWithGifts from '@/components/welcome-backgrounds/CloudsWithGifts'
    import Clouds from '@/components/welcome-backgrounds/Clouds'
    import Astypalaia from '@/components/welcome-backgrounds/Astypalaia'

    export default {
        components: {
            ResetPasswordForm,
            NewPasswordForm,
            LanguageSelectUnauthorized,
            CloudsWithGifts,
            Clouds,
            Astypalaia
        },
        data() {
            return {
                isPasswordResetRoute: true
            }
        },
        computed: {
            branding() {
                return this.$store.getters.getBranding
            },
            welcomeTheme() {
                return this.$store.getters.getWelcomeTheme
            }
        },
        created() {
            if (this.$route.params.resetToken) {
                this.isPasswordResetRoute = false
            }
        }
    }
</script>

<style lang="scss" scoped>
.forgot-password-route {
    width: 100%;
    min-height: 100vh;
    display: grid;
    place-items: center;
    position: relative;
}

.language-selector {
    position: absolute;
    top: 1%;
    left: 2%;
    z-index: 1;
}

.forgot-password-container {
    color: var(--white);
    max-width: 555px;
    width: 90%;
    margin: 0 auto;
    padding: 3rem 0 1rem;
    z-index: 1;
}

.forgot-password-title img {
    height: 95px;
}

.forgot-password-text {
    margin: 1rem 0 0;
    font-size: 1rem;
    line-height: 1.5;
}

@media screen and (min-width: 768px) {
    .language-selector {
        left: 1%;
    }

    .register-title .welcome-to {
        font-size: 1.3rem;
    }

    .register-title .frawards {
        font-size: 2.6rem;
    }

    .forgot-password-container {
        max-width: 420px;
        width: 100%;
    }

    .forgot-password-text {
        line-height: 1.5;
        font-size: 1.15rem;
        margin: 1rem 0;
    }
}
</style>
