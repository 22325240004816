<template>
    <b-overlay
        :show="isLoading"
        variant="transparent"
        class="overlay"
        :opacity="0.1"
        rounded="lg"
        spinner-type="border"
    >
        <form
            class="forgot-password-form"
            :class="{'disabled-form': isLoading}"
            @submit.prevent="submitNewPassword"
        >
            <div class="passwords-container">
                <div class="input-group">
                    <label>{{ $t('newPasswordForm.newPassword') }}</label>
                    <input
                        v-model="password"
                        type="password"
                        class="form-plain-input"
                    >
                </div>
                <div class="input-group">
                    <label>{{ $t('newPasswordForm.confirmNewPassword') }}</label>
                    <input
                        v-model="confirmPassword"
                        type="password"
                        class="form-plain-input"
                    >
                </div>
            </div>
            <div class="errors-container">
                <span
                    v-if="errorResponse"
                    class="error-message"
                >{{ errorResponse }}</span>
            </div>
            <button
                type="submit"
                class="custom-btn"
                :disabled="isSubmitDisabled"
                :class="{'disabled': isSubmitDisabled}"
            >
                {{ $t('newPasswordForm.submit') }}
            </button>
        </form>
    </b-overlay>
</template>

<script>
    import playerService from '@/services/player-service'
    export default {
        data() {
            return {
                password: null,
                confirmPassword: null,
                isLoading: false,
                errorResponse: null,
                errorMap: {
                    PASSWORD_MUST_BE_BETWEEN_6_AND_24_CHARACTERS: this.$t('newPasswordForm.passwordMustBeBetween6And24Characters'),
                    PASSWORDS_NOT_MATCH: this.$t('newPasswordForm.passwordsNotMatch'),
                    INVALID_CONFIRMATION_TOKEN: this.$t('newPasswordForm.invalidConfirmationToken'),
                    PASSWORD_MUST_NOT_CONTAIN_SPACES: this.$t('newPasswordForm.passwordMustNotContainSpaces')
                }
            }
        },
        computed: {
            isSubmitDisabled() {
                return (!this.password || !this.confirmPassword) || (this.password !== this.confirmPassword)
            }
        },
        methods: {
            submitNewPassword() {
                this.isLoading = true
                if (this.password?.length < 6 || this.password?.length > 24) {
                    this.errorResponse = this.errorMap['PASSWORD_MUST_BE_BETWEEN_6_AND_24_CHARACTERS']
                    this.isLoading = false
                    setTimeout(() => {
                        this.errorResponse = false
                    }, 5000)
                    return
                }
                if (this.password !== this.confirmPassword) {
                    this.errorResponse = this.errorMap['PASSWORDS_NOT_MATCH']
                    this.isLoading = false
                    setTimeout(() => {
                        this.errorResponse = false
                    }, 5000)
                    return
                }
                if (this.password.includes(' ') || this.confirmPassword.includes(' ')) {
                    this.errorResponse = this.errorMap['PASSWORD_MUST_NOT_CONTAIN_SPACES']
                    this.errorResponse = 'Password must not contain spaces'
                    this.isLoading = false
                    setTimeout(() => {
                        this.errorResponse = false
                    }, 5000)
                    return
                }

                if (this.$route.params.resetToken) {
                    playerService.newPassword(this.password, this.$route.params.resetToken).then((res) => {
                        this.$store.dispatch('logout')
                        this.$store.commit('login', res.data.token)
                        this.$router.push({ path: '/home' })
                    }, (error) => { this.handleError(error) }
                    ).finally(() => { this.isLoading = false })
                }
            },
            handleError(error) {
                const errorCode = error.response.data.code

                this.errorResponse = this.errorMap[errorCode]

                setTimeout(() => {
                    this.errorResponse = null
                }, 5000)
            }
        }
    }
</script>

<style scoped>
.forgot-password-form {
    max-width: 420px;
    margin: 1rem auto 0;
    color: var(--white);
    display: flex;
    flex-direction: column;
}

.tabs-container {
    margin-top: 1rem;
}

.approve-text {
    color: var(--white);
}

.terms-text {
    font-weight: 700;
    color: var(--white);
    text-decoration: none;
}

.form-plain-input {
    height: 48px;
    width: 90%;
    margin: 0 auto;
    background: #193674;
}

.custom-checkbox {
    margin: 1rem auto 0;
    display: block;
    position: relative;
    cursor: pointer;
    text-align: left;
    padding-left: 2rem;
    width: 90%;
}

input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.passwords-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    width: 100%;
    margin: 2rem 0 0;
}

.input-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.25rem;
    max-width: 420px;
    width: 100%;
}

.input-group label {
    align-self: start;
    font-size: 1.1rem;
}

.input-group input {
    width: 100%;
    height: 50px;
    font-size: 1.25rem;
    padding: 0 0.5rem;
    border-radius: 5px;
    border: 0;
    color: #009fe3;
}

.checkmark {
    position: absolute;
    top: 9%;
    left: 0;
    height: 1.1rem;
    width: 1.1rem;
    background-color: var(--white);
    border: 3px solid var(--white);
    transition: all 0.15s ease-out;
}

.custom-checkbox input:checked ~ .checkmark {
    background-color: #193674;
}

.errors-container {
    margin: 0.5rem 0;
    height: 1rem;
    font-weight: 700;
}

.errors-container .error-message {
    color: var(--form-error-color);
}

.custom-btn {
    max-width: 420px;
    width: 90%;
    margin: 2rem auto 1rem;
    height: 60px;
    border-radius: 20px;
    display: grid;
    place-items: center;
    font-size: 1.1rem;
    letter-spacing: 0.3em;
    font-weight: 500;
    background: #193674;
    transition: all 0.35s ease-in-out;
    cursor: pointer;
    color: var(--white);
    text-decoration: none;
    border: none;
}

.custom-btn:hover:enabled {
    background: #f1b12b;
}

.custom-btn:active:enabled {
    transform: scale(0.9);
}

.custom-btn.disabled {
    background: var(--disabled-color);
    cursor: not-allowed;
}

.email-sent {
    font-weight: 700;
    font-size: 14px;
    color: var(--black);
}

.disabled-form {
    opacity: 0.5;
}

.overlay {
    color: #f1b12b;
}

@media screen and (min-width: 768px) {
    .forgot-password-form {
        margin-top: 1rem;
        gap: 0;
        max-width: 420px;
    }

    .errors-container {
        height: 2rem;
    }

    .input-group input {
        padding: 0 1rem;
    }

    .input-group label {
        font-size: 1rem;
    }

    .form-plain-input {
        width: 100%;
    }

    .custom-btn {
        font-size: 1.25rem;
        height: 65px;
        width: 100%;
        margin: 2rem auto;
    }

    .custom-checkbox {
        width: 100%;
    }
}
</style>
