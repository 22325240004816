<template>
    <b-overlay
        :show="isLoading"
        variant="transparent"
        class="overlay"
        :opacity="0.1"
        rounded="lg"
        spinner-type="border"
    >
        <form
            class="forgot-password-form"
            :class="{'disabled-form': isLoading}"
            @submit.prevent="getAccessCode"
        >
            <tabs
                class="tabs-container"
                @tab-changed="onTabChanged"
            >
                <tab
                    :title="$t('resetPasswordForm.email')"
                    tab-title="email"
                >
                    <div class="form-group">
                        <input
                            v-model.trim="email"
                            type="email"
                            :placeholder="$t('registerRoute.emailPlaceholder')"
                            class="form-control form-plain-input"
                        >
                    </div>
                </tab>
                <tab
                    :title="$t('resetPasswordForm.mobile')"
                    tab-title="mobile"
                >
                    <div class="form-group">
                        <vue-phone-number-input
                            v-model.trim="playerMobile.phoneNumber"
                            :default-country-code="defaultCountryCode"
                            :preferred-countries="getPreferredLanguages"
                            name="tel"
                            class="welcome-phone-input"
                            no-example
                            :dark="false"
                            :dark-color="'#356c9a'"
                            :translations="phoneNumberLocale"
                            :border-radius="5"
                            valid-color="#009FE3"
                            error-color="red"
                            no-flags
                            :error="playerMobile.phoneNumber && !isPhoneNumberValid"
                            @update="mobileChanged"
                        />
                    </div>
                </tab>
            </tabs>
            <div class="errors-container">
                <span
                    v-if="resetFailedError"
                    class="error-message"
                >{{ resetFailedError }}</span>
                <span v-else-if="otpError" class="error-message">
                    {{ otpError }} <span v-if="expirationTime">{{ expirationTime }} {{ $t('resetPasswordForm.minutes') }}</span>
                </span>
                <span v-else-if="emailWasSent">
                    {{ $t('resetPasswordForm.emailWasSent') }}
                </span>
            </div>
            <button
                type="submit"
                class="custom-btn"
                :disabled="isGetCodeDisabled"
                :class="{'disabled': isGetCodeDisabled}"
            >
                {{ $t('resetPasswordForm.resetPassword') }}
            </button>
            <router-link to="/login" class="go-to-login">
                {{ $t('resetPasswordForm.return') }}
            </router-link>
        </form>
    </b-overlay>
</template>

<script>
    import Tab from '@/components/tabs/Tab'
    import Tabs from '@/components/tabs/Tabs'
    import playerService from '@/services/player-service'
    import VuePhoneNumberInput from 'vue-phone-number-input'
    import 'vue-phone-number-input/dist/vue-phone-number-input.css'
    export default {
        components: {
            Tab,
            Tabs,
            VuePhoneNumberInput
        },
        data() {
            return {
                email: null,
                mobile: null,
                playerMobile: {
                    countryCode: 'DE',
                    phoneNumber: null
                },
                isPhoneNumberValid: true,
                isLoading: false,
                phoneNumberLocale: {
                    countrySelectorLabel: '',
                    phoneNumberLabel: this.$t('registerRoute.mobilePlaceholder')
                },
                emailWasSent: false,
                resetFailedError: null,
                errorMap: {
                    PLAYER_NOT_EXISTS: this.$t('resetPasswordForm.userNotFound'),
                    INVALID_EMAIL: this.$t('resetPasswordForm.invalidEmail'),
                    RESET_PASSWORD_ACTIVE_RESET_REQUEST: this.$t('resetPasswordForm.alreadyRequested')
                },
                expirationTime: null,
                otpError: null
            }
        },
        computed: {
            isGetCodeDisabled() {
                return (!this.email && !this.mobile) || this.emailWasSent
            },
            getPreferredLanguages() {
                return this.$store.getters.getPreferredLanguages || []
            },
            defaultCountryCode() {
                return this.getPreferredLanguages?.length > 0 ? this.getPreferredLanguages[0] : ''
            }
        },
        methods: {
            getAccessCode() {
                this.isLoading = true
                let data = null
                if (this.email && !this.isEmailValid(this.email)) {
                    this.resetFailedError = this.errorMap['INVALID_EMAIL']
                    setTimeout(() => {
                        this.resetFailedError = null
                    }, 5000)
                    this.isLoading = false
                    return
                }

                if (this.email) {
                    data = this.email
                } else if (this.mobile) {
                    data = this.mobile
                }
                playerService.passwordReset(data)
                    .then(() => {
                        this.emailWasSent = true
                        this.$router.push('/login')
                    }, (error) => {
                        this.handleResetPasswordError(error)
                    })
                    .finally(() => { this.isLoading = false })
            },
            onTabChanged(tab) {
                if (tab.$attrs['tab-title'] === 'mobile') {
                    this.email = null
                } else if (tab.$attrs['tab-title'] === 'email') {
                    this.mobile = null
                    this.playerMobile.phoneNumber = null
                }
            },
            mobileChanged(phone) {
                this.playerMobile.countryCode = phone.countryCallingCode
                if (!phone.hasOwnProperty('phoneNumber')) {
                    this.mobile = null
                }
                if (phone.hasOwnProperty('isValid')) {
                    this.isPhoneNumberValid = phone.isValid
                }
                if (phone?.formattedNumber) {
                    this.mobile = phone.formattedNumber.substring(1)
                }
            },
            handleResetPasswordError(error) {
                const errorCode = error.response.data.code
                if (errorCode === 'RESET_PASSWORD_ACTIVE_RESET_REQUEST') {
                    // TODO: show otp request expiration time
                    this.otpError = this.errorMap[errorCode]
                    this.expirationTime = error.response?.data?.tryAgainAfterMinutes
                } else {
                    this.resetFailedError = this.errorMap[errorCode]
                }

                setTimeout(() => {
                    this.resetFailedError = null
                    this.otpError = null
                }, 5000)
            },
            isEmailValid(email) {
                let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                return regex.test(email)
            }
        }
    }
</script>
<style>
.welcome-phone-input.vue-phone-number-input {
    gap: 1rem;
    min-height: 48px;
}

.welcome-phone-input .select-country-container {
    min-height: 48px;
    width: 90px !important;
    min-width: 90px !important;
    max-width: 90px !important;
}

.welcome-phone-input .input-country-selector {
    min-height: 48px;
}

.welcome-phone-input .input-tel__input::placeholder {
    color: #009fe3 !important;
}

.welcome-phone-input .country-selector__input,
.welcome-phone-input .input-tel__input {
    height: 48px;
    padding: 0  1rem !important;
    font-size: 1.1rem;
    font-family: var(--font-family) !important;
    color: #009fe3 !important;
}

.welcome-phone-input .country-selector__label,
.welcome-phone-input .input-tel__label {
    display: none !important;
}

@media screen and (min-width: 768px) {
    .welcome-phone-input .country-selector__input,
    .welcome-phone-input .input-tel__input {
        font-size: 1.2rem !important;
    }
}

</style>

<style scoped>
.forgot-password-form {
    max-width: 420px;
    margin: 1rem auto 0;
    color: var(--white);
    display: flex;
    flex-direction: column;
    padding: 0 0 2rem;
}

.tabs-container {
    margin-top: 1rem;
}

.approve-text {
    color: var(--white);
}

.terms-text {
    font-weight: 700;
    color: var(--white);
    text-decoration: none;
}

.form-control::placeholder {
    color: #009fe3;
    opacity: 0.6;
}

.form-plain-input {
    height: 48px;
    width: 90%;
    margin: 0 auto;
    font-size: 1.1rem !important;
    color: #009fe3;
}

.custom-checkbox {
    margin: 1rem auto 0;
    display: block;
    position: relative;
    cursor: pointer;
    text-align: left;
    padding-left: 2rem;
    width: 90%;
}

input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    top: 9%;
    left: 0;
    height: 1.1rem;
    width: 1.1rem;
    background-color: var(--white);
    border: 3px solid var(--white);
    transition: all 0.15s ease-out;
}

.custom-checkbox input:checked ~ .checkmark {
    background-color: #193674;
}

.errors-container {
    margin: 0.5rem 0;
    height: 1rem;
    font-weight: 700;
}

.errors-container .error-message {
    color: var(--form-error-color);
}

.custom-btn {
    max-width: 420px;
    width: 90%;
    margin: 2rem auto 0.75rem;
    height: 60px;
    border-radius: 20px;
    display: grid;
    place-items: center;
    font-size: 1.1rem;
    letter-spacing: 0.3em;
    font-weight: 500;
    background: #193674;
    transition: all 0.35s ease-in-out;
    cursor: pointer;
    color: var(--white);
    text-decoration: none;
    border: none;
}

.custom-btn:hover:enabled {
    background: #f1b12b;
}

.custom-btn:active:enabled {
    transform: scale(0.9);
}

.custom-btn.disabled {
    background: var(--disabled-color);
    cursor: not-allowed;
}

.email-sent {
    font-weight: 700;
    font-size: 14px;
    color: var(--black);
}

.disabled-form {
    opacity: 0.5;
}

.overlay {
    color: #f1b12b;
}

.go-to-login {
    display: block;
    font-size: 0.9rem;
    letter-spacing: 0.3em;
    color: var(--white);
}

@media screen and (min-width: 768px) {
    .forgot-password-form {
        margin-top: 2rem;
        gap: 0;
    }

    .errors-container {
        height: 2rem;
    }

    .form-plain-input {
        width: 100%;
        font-size: 1.2rem !important;
    }

    .custom-btn {
        font-size: 1.25rem;
        height: 65px;
        width: 100%;
        margin: 2rem auto 1rem;
    }

    .custom-checkbox {
        width: 100%;
    }
}
</style>
